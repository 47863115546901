import React, { useLayoutEffect, useRef, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SearchIcon from "@mui/icons-material/Search";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch } from "react-redux";
import StyledButton from "../shared/StyledButton";
import { styled } from "@mui/system";
import InputBase from "@mui/material/InputBase";
import PlusIcon from "../../assets/svg/plus.svg";
import RefreshIcon from "../../assets/svg/refresh.svg";
import UploadIcon from "../../assets/svg/upload.svg";
import { logout } from "../../store/slices/authSlice";
import { useSearch } from "../../context/SearchContext";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  border: "1px solid white",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "15ch",
      "&:focus": {
        width: "25ch",
      },
    },
  },
}));

const ToolbarGroup = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const ToolbarSpacer = styled("div")({
  flexGrow: 1,
});

const StyledIconButton = styled(IconButton)({
  marginRight: "5px",
});

const TopToolbar = ({ handleDrawerToggle }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const { searchTerm, setSearchTerm } = useSearch();
  const [anchorEl, setAnchorEl] = useState(null);
  const toolbarRef = useRef(null);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const updateToolbarWidth = () => {
    const toolbarWidth = toolbarRef.current?.clientWidth;
    console.log("Toolbar Width:", toolbarWidth);
    if (toolbarWidth) {
      localStorage.setItem("toolbarWidth", toolbarWidth);
    }
  };

  useLayoutEffect(() => {
    updateToolbarWidth();

    window.addEventListener("resize", updateToolbarWidth);
    return () => {
      window.removeEventListener("resize", updateToolbarWidth);
    };
  }, []);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    handleMenuClose();
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{
        boxShadow: "0px 4px 6px 0px #00000040",
        zIndex: theme.zIndex.drawer + 1,
      }}
      ref={toolbarRef}
    >
      <Toolbar>
        <ToolbarGroup>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          {!isMobile && (
            <img
              src={require("../../assets/images/MOVU_logo_white.png")}
              alt="Logo"
              style={{
                width: "130px",
              }}
            />
          )}
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Search>
          {!isMobile && (
            <>
              <StyledButton
                type="submit"
                variant="contained"
                marginright="12px"
              >
                <img
                  src={PlusIcon}
                  alt="Plus"
                  style={{
                    marginRight: "8px",
                    height: "17px",
                    width: "17px",
                    filter: "invert(1)",
                  }}
                />
                New Patient
              </StyledButton>
              <StyledButton
                type="submit"
                variant="contained"
                marginright="12px"
              >
                <img
                  src={RefreshIcon}
                  alt="Refresh"
                  style={{
                    marginRight: "8px",
                    height: "17px",
                    width: "17px",
                    filter: "invert(1)",
                  }}
                />
                Refresh
              </StyledButton>
              <StyledButton
                type="submit"
                variant="contained"
                marginright="12px"
              >
                <img
                  src={UploadIcon}
                  alt="Upload"
                  style={{
                    marginRight: "8px",
                    height: "17px",
                    width: "17px",
                    filter: "invert(1)",
                  }}
                />
                Export
              </StyledButton>
            </>
          )}
        </ToolbarGroup>
        <ToolbarSpacer />
        <ToolbarGroup>
          <StyledIconButton color="inherit">
            <NotificationsIcon />
          </StyledIconButton>
          <StyledIconButton
            edge="end"
            color="inherit"
            aria-controls="account-menu"
            aria-haspopup="true"
            onClick={handleMenuOpen}
          >
            <AccountCircle />
          </StyledIconButton>
          <Menu
            id="account-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: "#000000",
                color: "#ffffff",
              },
            }}
          >
            <MenuItem onClick={handleLogout}>Log Out</MenuItem>
          </Menu>
        </ToolbarGroup>
      </Toolbar>
    </AppBar>
  );
};

export default TopToolbar;
