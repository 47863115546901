import { zipSync } from 'fflate';
import { saveByteArray } from '../utils/fileUtils';
let isCancelled = false;
async function fetchDicomStudy(studyUrl, headers, onProgress) {
  const response = await fetch(studyUrl, { headers });
  const reader = response.body.getReader();
  const chunks = [];
  let receivedLength = 0;

  const contentLength = response.headers.get('Content-Length');
  const totalSize = contentLength ? parseInt(contentLength, 10) : null;

  let done;
  while (!done && !isCancelled) {
    const { value, done: isDone } = await reader.read();
    done = isDone;
    if (value) {
      chunks.push(value);
      receivedLength += value.length;

      if (onProgress && totalSize) {
        const percentComplete = (receivedLength / totalSize) * 100;
        onProgress(percentComplete);
      }
    }
  }

  if (isCancelled) {
    console.log('Download was cancelled');
    return [];
  }

  const byteArray = new Uint8Array(receivedLength);
  let position = 0;
  for (const chunk of chunks) {
    byteArray.set(chunk, position);
    position += chunk.length;
  }

  const boundary = getBoundary(response.headers.get('Content-Type'));
  const dicomParts = parseMultipart(byteArray, boundary);
  return dicomParts.map(part => part.content);
}

function getBoundary(contentType) {
  const boundaryMatch = /boundary="?([^;"]+)"?/.exec(contentType);
  return boundaryMatch ? boundaryMatch[1] : null;
}

function parseMultipart(byteArray, boundary) {
  const boundaryBytes = new TextEncoder().encode('--' + boundary);
  const newlineBytes = new TextEncoder().encode('\r\n');
  const parts = [];
  let start = findBoundary(byteArray, boundaryBytes, 0);

  while (start !== -1) {
    const end = findBoundary(byteArray, boundaryBytes, start + boundaryBytes.length);
    if (end === -1) {
      break;
    }
    const partBytes = byteArray.subarray(
      start + boundaryBytes.length + newlineBytes.length,
      end - newlineBytes.length
    );
    const headersEndIndex = findBoundary(partBytes, newlineBytes, 0) + newlineBytes.length;
    const content = partBytes.subarray(headersEndIndex);
    parts.push({ content });
    start = end;
  }
  return parts;
}

function findBoundary(byteArray, boundaryBytes, fromIndex) {
  for (let i = fromIndex; i <= byteArray.length - boundaryBytes.length; i++) {
    if (boundaryBytes.every((byte, index) => byteArray[i + index] === byte)) {
      return i;
    }
  }
  return -1;
}

export const downloadZippedStudy = async (studyUrl, headers, zipFileName, onProgress) => {
  const dicomFiles = await fetchDicomStudy(studyUrl, headers, onProgress);
  if (dicomFiles.length === 0) return;

  const filesObject = {};
  dicomFiles.forEach((file, index) => {
    const fileName = (index + 1).toString().padStart(4, '0');
    filesObject[`${fileName}.dcm`] = file;
  });

  const zipped = zipSync(filesObject);
  saveByteArray(zipped, zipFileName, 'application/zip');
};

export const cancelDownload = () => {
    isCancelled = true;
  };
  
export const resetCancelDownload = () => {
   isCancelled = false;
};
  