import React, { useEffect, useState } from "react";
import api from "../../api/config";
import { RadiologyReport } from "../../api/Patient";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  Collapse,
  IconButton,
  TableSortLabel,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import EhrSearch from "../shared/EhrSearch";

const RadiologyContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledTableContainer = styled(TableContainer)`
  flex-grow: 1;
  overflow-y: auto;
`;

const StyledTableCell = styled(TableCell)`
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
`;

const PaginationContainer = styled.div`
  padding: 10px;
`;

const useStyles = makeStyles({
  headerCell: {
    backgroundColor: "#3E526A !important",
    color: "#A5E0FF !important",
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: "14px",
  },
  tableRow: {
    "& .MuiTableCell-head": {
      backgroundColor: "#3E526A !important",
      color: "#A5E0FF !important",
      textTransform: "capitalize",
      fontWeight: 600,
      fontSize: "14px",
    },
  },
});

const parseDate = (dateString) => {
  if (!dateString) return null;

  if (/^\d{8}$/.test(dateString)) {
    return new Date(
      `${dateString.slice(0, 4)}-${dateString.slice(4, 6)}-${dateString.slice(
        6,
        8
      )}`
    );
  }

  const date = new Date(dateString);

  if (!isNaN(date)) {
    return date;
  }

  return null;
};

const Radiology = ({ selectedPatient }) => {
  const [radiologyData, setRadiologyData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: "date_of_imaging",
    direction: "desc",
  });

  useEffect(() => {
    const fetchRadiologyData = async (retryCount = 0) => {
      try {
        if (selectedPatient && selectedPatient.uuid) {
          const response = await api.get(
            `${RadiologyReport}/${selectedPatient.uuid}`
          );
          if (response.data.length > 0) {
            setRadiologyData(response.data);
            setError(false);
          } else {
            setRadiologyData([]);
          }
        } else {
          console.warn("No selected patient or missing UUID");
          setRadiologyData([]);
        }
      } catch (error) {
        console.error("Error fetching radiology data:", error);
        if (retryCount < 3) {
          fetchRadiologyData(retryCount + 1);
        } else {
          setError(true);
          setRadiologyData([]);
        }
      }
    };

    if (selectedPatient) {
      fetchRadiologyData();
    }
  }, [selectedPatient]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = radiologyData
    ? [...radiologyData].sort((a, b) => {
        let valueA, valueB;

        switch (sortConfig.key) {
          case "site_of_service":
            valueA = a.site_of_service?.toLowerCase() || "";
            valueB = b.site_of_service?.toLowerCase() || "";
            break;
          case "study_description":
            valueA = a.study_description?.toLowerCase() || "";
            valueB = b.study_description?.toLowerCase() || "";
            break;
          case "date_of_imaging":
          default:
            valueA = parseDate(a.date_of_imaging);
            valueB = parseDate(b.date_of_imaging);
            break;
        }

        if (valueA < valueB) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      })
    : [];

  const filteredData = sortedData.filter(
    (item) =>
      item.site_of_service?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.date_of_imaging?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.study_description?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <RadiologyContainer>
      <EhrSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

      {error || (radiologyData && radiologyData.length === 0) ? (
        <p>No Radiology Data...</p>
      ) : !radiologyData ? (
        <p>Loading radiology data...</p>
      ) : (
        <>
          <StyledTableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <StyledTableCell>#</StyledTableCell>
                  <StyledTableCell className={classes.headerCell}>
                    <TableSortLabel
                      active={sortConfig.key === "site_of_service"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("site_of_service")}
                    >
                      Organization Name
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell className={classes.headerCell}>
                    <TableSortLabel
                      active={sortConfig.key === "date_of_imaging"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("date_of_imaging")}
                    >
                      Date
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell className={classes.headerCell}>
                    <TableSortLabel
                      active={sortConfig.key === "study_description"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("study_description")}
                    >
                      Note
                    </TableSortLabel>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <React.Fragment key={index}>
                      <TableRow hover onClick={() => handleRowClick(index)}>
                        <TableCell style={{ padding: "10px" }}>
                          <IconButton aria-label="expand row" size="small">
                            {expandedRow === index ? (
                              <KeyboardArrowUp
                                style={{ filter: "invert(1)" }}
                              />
                            ) : (
                              <KeyboardArrowDown
                                style={{ filter: "invert(1)" }}
                              />
                            )}
                          </IconButton>{" "}
                          {index + 1}
                        </TableCell>
                        <TableCell style={{ padding: "10px" }}>
                          {item.site_of_service || "N/A"}
                        </TableCell>
                        <TableCell style={{ padding: "10px" }}>
                          {item.date_of_imaging || "N/A"}
                        </TableCell>
                        <TableCell style={{ padding: "10px" }}>
                          {item.study_description || "N/A"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={4}
                        >
                          <Collapse
                            in={expandedRow === index}
                            timeout="auto"
                            unmountOnExit
                          >
                            <div
                              style={{
                                margin: "5px 10px",
                                padding: "5px 10px",
                                display: "flex",
                                justifyContent: "space-between",
                                flexWrap: "wrap",
                              }}
                            >
                              <div
                                style={{
                                  flex: "1 1 50%",
                                  wordWrap: "break-word",
                                  whiteSpace: "normal",
                                }}
                              >
                                <strong>Date:</strong>{" "}
                                {item.date_of_imaging || "N/A"}
                              </div>
                              <div
                                style={{
                                  flex: "1 1 50%",
                                  wordWrap: "break-word",
                                  whiteSpace: "normal",
                                }}
                              >
                                <strong>Organization Name:</strong>{" "}
                                {item.site_of_service || "N/A"}
                              </div>
                            </div>
                            <div
                              style={{
                                margin: "5px 10px",
                                padding: "5px 10px",
                              }}
                            >
                              <strong>Status:</strong>{" "}
                              {item.study_modality || "N/A"}
                            </div>
                            <div
                              style={{
                                margin: "5px 10px",
                                padding: "5px 10px",
                                maxWidth: "30vw",
                                wordWrap: "break-word",
                                whiteSpace: "normal",
                                overflow: "hidden",
                              }}
                            >
                              <p
                                style={{
                                  whiteSpace: "normal",
                                  wordBreak: "break-word",
                                  margin: 0,
                                  padding: 0,
                                }}
                              >
                                <strong>Group:</strong>{" "}
                                {item.study_report || "N/A"}
                              </p>
                            </div>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
          <PaginationContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              slotProps={{
                select: {
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        backgroundColor: "#141b25",
                        color: "#FBFEFF",
                      },
                    },
                  },
                },
              }}
            />
          </PaginationContainer>
        </>
      )}
    </RadiologyContainer>
  );
};

export default Radiology;
