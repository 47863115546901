import React from "react";
import styled from "styled-components";

const InfoTable = styled.div`
  background-color: #3b4252;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px 0px #00000040;
  flex: ${({ flex }) => flex || 1};
`;

const TableTitle = styled.p`
  margin-top: 0;
  background-color: #3e526a;
  color: #7ef5fd;
  padding: 10px;
  border-radius: 8px;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const PatientInformation = ({ patient }) => (
  <div style={{ marginTop: "16px" }}>
    <InfoTable flex={2}>
      <TableTitle>Patient Information</TableTitle>
      <InfoRow>
        <span>Birth Date:</span>
        <span>{patient.date_of_birth}</span>
      </InfoRow>
      <InfoRow>
        <span>Gender:</span>
        <span>{patient.gender}</span>
      </InfoRow>
      <InfoRow>
        <span>Phone:</span>
        <span>{patient.phone || "N/A"}</span>
      </InfoRow>
      <InfoRow>
        <span>Address:</span>
        <span>{`${patient.address_street || ""} ${patient.address_city || ""} ${
          patient.address_state || ""
        } ${patient.address_zip || ""}`}</span>
      </InfoRow>
    </InfoTable>
  </div>
);

export default PatientInformation;
