import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../store/slices/authSlice";
import {
  Typography,
  InputAdornment,
  IconButton,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import StyledTextField from "../../components/shared/StyledTextField";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import StyledButton from "../../components/shared/StyledButton";
import {
  MainContainer,
  LoginPanel,
  Content,
  HeroImage,
  FormContainer,
  Logo,
  Form,
  Footer,
  ForgotPasswordLink,
  ForgotPasswordText,
} from "./Login.styles";

const LoginPage = ({ showNotification }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.token) {
      navigate("/dashboard");
    }
  }, [auth.token, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true); 
    try {
      await dispatch(login({ email, password })).unwrap();
      showNotification("Login successful", "success");
      navigate("/dashboard");
    } catch (error) {
      console.error("Login error:", error);
      if (error.status === 400) {
        showNotification(error.data.detail, "error");
      } else if (error.status === 422) {
        const validationErrors = error.data.detail
          .map((err) => err.msg)
          .join(", ");
        showNotification(validationErrors, "error");
      } else {
        showNotification("Login failed", "error");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <MainContainer>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <LoginPanel>
        <Content>
          <HeroImage
            src={require("../../assets/images/login_viewer_list1.png")}
            alt="Hero"
          />
          <FormContainer>
            <Logo
              src={require("../../assets/images/MOVU_logo_white.png")}
              alt="Logo"
            />
            <Form onSubmit={handleLogin}>
              <StyledTextField
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: Boolean(email) || undefined,
                }}
              />
              <StyledTextField
                label="Password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        sx={{ color: "#A5E0FF" }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: Boolean(password) || undefined,
                }}
              />
              <ForgotPasswordText variant="body2">
                <ForgotPasswordLink href="/forgot-password">
                  Forgot Password?
                </ForgotPasswordLink>
              </ForgotPasswordText>
              <div style={{ marginTop: "50px" }}>
                <StyledButton type="submit" variant="contained" fullWidth>
                  Log In
                </StyledButton>
              </div>
            </Form>
            <Footer>
              <Typography variant="body2" style={{ color: "#ffffff" }}>
                New user? Create an account.{" "}
              </Typography>
              <Typography
                variant="body2"
                style={{ color: "#A5E0FF", marginTop: "10px" }}
              >
                <ForgotPasswordLink href="/signup">Sign Up</ForgotPasswordLink>
              </Typography>
              <Typography
                variant="body2"
                style={{ color: "#9DA2A7", marginTop: "30px" }}
              >
                Powered by Nexus-MD
              </Typography>
            </Footer>
          </FormContainer>
        </Content>
      </LoginPanel>
    </MainContainer>
  );
};

export default LoginPage;
