import React, { useState, useEffect } from 'react';
import { Modal, Backdrop, Button, CircularProgress } from '@mui/material';
import styled from 'styled-components';

const DownloadModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #141b25;
  padding: 20px;
  border-radius: 10px;
  color: white;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20vh;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 10px;
  background-color: #333;
  border-radius: 5px;
  margin-top: 15px;
`;

const Progress = styled.div`
  height: 100%;
  width: ${({ progress }) => `${progress}%`};
  background-color: #007bff;
  border-radius: 5px;
`;

const DownloadProgressModal = ({ open, progress, message, onCancel }) => {
  return (
    <Modal
      open={open}
    >
      <DownloadModalContainer>
        <h3>Downloading Study Files</h3>
        <p>{message}</p>
        <ProgressBar>
          <Progress progress={progress} />
        </ProgressBar>
        <Button
          variant="contained"
          color="primary"
          onClick={onCancel}
          style={{ marginTop: '20px' }}
        >
          Cancel
        </Button>
      </DownloadModalContainer>
    </Modal>
  );
};

export default DownloadProgressModal;
