import React, { useEffect, useState } from "react";
import api from "../../api/config";
import { PatientObservation } from "../../api/Patient";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  Collapse,
  IconButton,
  TableSortLabel,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import EhrSearch from "../shared/EhrSearch";

const StyledTableContainer = styled(TableContainer)`
  flex-grow: 1;
  overflow-y: auto;
`;

const StyledTableCell = styled(TableCell)`
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
`;

const PaginationContainer = styled.div`
  padding: 10px;
`;

const useStyles = makeStyles({
  headerCell: {
    backgroundColor: "#3E526A !important",
    color: "#A5E0FF !important",
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: "14px",
  },
  tableRow: {
    "& .MuiTableCell-head": {
      backgroundColor: "#3E526A !important",
      color: "#A5E0FF !important",
      textTransform: "capitalize",
      fontWeight: 600,
      fontSize: "14px",
    },
  },
});

const parseDateTime = (dateTimeString) => {
  if (!dateTimeString) return null;

  if (/^\d{8}$/.test(dateTimeString)) {
    return new Date(
      `${dateTimeString.slice(0, 4)}-${dateTimeString.slice(
        4,
        6
      )}-${dateTimeString.slice(6, 8)}`
    );
  }

  const date = new Date(dateTimeString);

  if (!isNaN(date)) {
    return date;
  }

  return null;
};

const Imaging = ({ selectedPatient }) => {
  const [ImagingData, setImagingData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expandedRow, setExpandedRow] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "effective",
    direction: "desc",
  });
  const classes = useStyles();
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchImagingData = async (retryCount = 0) => {
      try {
        console.log("Selected Patient:", selectedPatient);

        if (selectedPatient && selectedPatient.uuid) {
          const response = await api.get(
            `${PatientObservation}/${selectedPatient.uuid}`
          );
          if (response.data.length > 0) {
            setImagingData(response.data);
            setError(false);
          } else {
            setImagingData([]);
          }
        } else {
          console.warn("No selected patient or missing UUID");
          setImagingData([]);
        }
      } catch (error) {
        console.error("Error fetching Imaging data:", error);
        if (retryCount < 3) {
          fetchImagingData(retryCount + 1);
        } else {
          setError(true);
          setImagingData([]);
        }
      }
    };

    if (selectedPatient) {
      fetchImagingData();
    }
  }, [selectedPatient]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = ImagingData
    ? [...ImagingData].sort((a, b) => {
        let valueA, valueB;
        switch (sortConfig.key) {
          case "subject":
            valueA = a.subject?.display?.toLowerCase() || "";
            valueB = b.subject?.display?.toLowerCase() || "";
            break;
          case "status":
            valueA = a.status?.toLowerCase() || "";
            valueB = b.status?.toLowerCase() || "";
            break;
          case "category":
            valueA = a.category[0]?.coding[0]?.display?.toLowerCase() || "";
            valueB = b.category[0]?.coding[0]?.display?.toLowerCase() || "";
            break;
          case "code":
            valueA = a.code?.text?.toLowerCase() || "";
            valueB = b.code?.text?.toLowerCase() || "";
            break;
          case "effective":
          default:
            valueA = parseDateTime(a.effective?.dateTime);
            valueB = parseDateTime(b.effective?.dateTime);
            break;
        }
        if (valueA < valueB) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      })
    : [];

  const filteredData = sortedData.filter(
    (item) =>
      item.subject?.display?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.status?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.category[0]?.coding[0]?.display
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      item.code?.text?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.effective?.dateTime?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleRowClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const parseContainedData = (containedString) => {
    try {
      return JSON.parse(containedString);
    } catch (e) {
      console.error("Error parsing contained data:", e);
      return null;
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <EhrSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

      {error || (ImagingData && ImagingData.length === 0) ? (
        <p>No Imaging Data...</p>
      ) : !ImagingData ? (
        <p>Loading Imaging data...</p>
      ) : (
        <>
          <StyledTableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <StyledTableCell>#</StyledTableCell>
                  <StyledTableCell className={classes.headerCell}>
                    <TableSortLabel
                      active={sortConfig.key === "subject"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("subject")}
                    >
                      Subject
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell className={classes.headerCell}>
                    <TableSortLabel
                      active={sortConfig.key === "status"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("status")}
                    >
                      Status
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell className={classes.headerCell}>
                    <TableSortLabel
                      active={sortConfig.key === "category"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("category")}
                    >
                      Category
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell className={classes.headerCell}>
                    <TableSortLabel
                      active={sortConfig.key === "code"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("code")}
                    >
                      Code
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell className={classes.headerCell}>
                    <TableSortLabel
                      active={sortConfig.key === "effective"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("effective")}
                    >
                      Effective Date
                    </TableSortLabel>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <React.Fragment key={index}>
                      <TableRow hover onClick={() => handleRowClick(index)}>
                        <StyledTableCell style={{ padding: "10px" }}>
                          <IconButton aria-label="expand row" size="small">
                            {expandedRow === index ? (
                              <KeyboardArrowUp
                                style={{ filter: "invert(1)" }}
                              />
                            ) : (
                              <KeyboardArrowDown
                                style={{ filter: "invert(1)" }}
                              />
                            )}
                          </IconButton>
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell style={{ padding: "10px" }}>
                          {item.subject?.display || "N/A"}
                        </StyledTableCell>
                        <StyledTableCell style={{ padding: "10px" }}>
                          {item.status || "N/A"}
                        </StyledTableCell>
                        <StyledTableCell style={{ padding: "10px" }}>
                          {item.category[0]?.coding[0]?.display || "N/A"}
                        </StyledTableCell>
                        <StyledTableCell style={{ padding: "10px" }}>
                          {item.code?.text || "N/A"}
                        </StyledTableCell>
                        <StyledTableCell style={{ padding: "10px" }}>
                          {item.effective?.dateTime || "N/A"}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={7}
                        >
                          <Collapse
                            in={expandedRow === index}
                            timeout="auto"
                            unmountOnExit
                          >
                            <div style={{ margin: 10, padding: 10 }}>
                              {item.contained && item.contained.length > 0 && (
                                <Table>
                                  <TableHead className={classes.tableRow}>
                                    <TableRow>
                                      <StyledTableCell>Code</StyledTableCell>
                                      <StyledTableCell>Value</StyledTableCell>
                                      <StyledTableCell>Unit</StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {item.contained.map(
                                      (containedItem, containedIndex) => {
                                        const parsedItem =
                                          parseContainedData(containedItem);
                                        if (!parsedItem) return null;

                                        return (
                                          <TableRow key={containedIndex}>
                                            <StyledTableCell>
                                              {parsedItem.code?.coding[0]
                                                ?.display || "N/A"}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              {parsedItem.value?.quantity
                                                ?.value || "N/A"}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              {parsedItem.value?.quantity
                                                ?.unit || "N/A"}
                                            </StyledTableCell>
                                          </TableRow>
                                        );
                                      }
                                    )}
                                  </TableBody>
                                </Table>
                              )}
                            </div>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
          <PaginationContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              slotProps={{
                select: {
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        backgroundColor: "#141b25",
                        color: "#FBFEFF",
                      },
                    },
                  },
                },
              }}
            />
          </PaginationContainer>
        </>
      )}
    </div>
  );
};

export default Imaging;
