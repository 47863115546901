import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import axios from "axios";
import api from "../../api/config";
import { GetPatientMetaData, GetPatientStudyMeta } from "../../api/Patient";
import { DICOMProxyToken, ViewerPath } from "../../api/Authentication";
import {
  downloadZippedStudy,
  cancelDownload,
  resetCancelDownload,
} from "../../services/downloadService";
import DownloadProgressModal from "../shared/DownloadProgressModal";

const InfoTable = styled.div`
  background-color: #3b4252;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px 0px #00000040;
  flex: ${({ flex }) => flex || 1};
`;

const StyledTableContainer = styled(TableContainer)`
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: transparent !important;
  flex-grow: 1;
`;

const StyledPaper = styled(Paper)`
  background-color: transparent !important;
  color: #fbfeff !important;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const BasicButton = styled(Button)`
  color: #fbfeff !important;
  border: 1px solid #fbfeff !important;
  border-radius: 8px !important;
  &:hover {
    background-color: #3e526a !important;
  }
`;

const StyledTableRow = styled(TableRow)`
  td {
    padding: 5px;
  }
  &:hover {
    background-color: #5a6285 !important;
  }

  &.selected-row td {
    background-color: #a5e0ff !important;
    color: #000000 !important;
  }
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

const ThumbnailHeader = styled.div`
  padding: 8px;
  color: black;
  background-color: #a5e0ff;
`;

const OuterContainer = styled.div`
  max-width: 100%;
  overflow-x: auto;
  padding: 16px;
  white-space: nowrap;
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #283a52;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background: #28929c;
    border: 1px solid #3e526a;
    border-radius: 100px;
  }
`;

const ThumbnailList = styled.div`
  display: flex;
`;

const ThumbnailListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  & img {
    margin-right: 10px;
  }
`;

const useStyles = makeStyles({
  headerCell: {
    backgroundColor: "#3E526A !important",
    color: "#A5E0FF !important",
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: "14px",
  },
  tableRow: {
    "& .MuiTableCell-head": {
      backgroundColor: "#3E526A !important",
      color: "#A5E0FF !important",
      textTransform: "capitalize",
      fontWeight: 600,
      fontSize: "14px",
    },
  },
});

const PatientStudies = ({ patient }) => {
  const [studies, setStudies] = useState(null);
  const [loadingThumbnails, setLoadingThumbnails] = useState(false);
  const [thumbnails, setThumbnails] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(null);
  const [loadingDownloadModal, setLoadingDownloadModal] = useState(false);
  const [downloadMessage, setDownloadMessage] = useState(
    "Starting download..."
  );
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [selectedStudy, setSelectedStudy] = useState(null);
  const classes = useStyles();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "",
  });
  useEffect(() => {
    console.log("Patient:", patient);

    const fetchPatientMetaData = async () => {
      setStudies(null);
      setTotalRows(null);
      setThumbnails({});
      try {
        const response = await api.get(`${GetPatientMetaData}/${patient.uuid}`);
        setStudies(response.data);
        setTotalRows(response.data.length);
      } catch (error) {
        console.error("Failed to fetch patient meta data", error);
        setStudies([]);
        setTotalRows(0);
      }
    };

    if (patient) {
      fetchPatientMetaData();
    }
  }, [patient]);
  const handleThumbnailClick = async (study) => {
    try {
      const tokenResponse = await api.get(DICOMProxyToken);
      const dicomToken = tokenResponse.data.token;
      const nexusToken = localStorage.getItem("token");
      const selectedPatientId = patient.uuid;
      window.open(
        `${ViewerPath}?StudyInstanceUIDs=${study.StudyInstanceUID}&token=${dicomToken}&nexusToken=${nexusToken}&patientId=${selectedPatientId}&origin=movu&modality=${study.Modality}`
      );
    } catch (error) {
      console.error("Error processing DICOM file:", error);
    } finally {
    }
  };

  const getThumbnailUrl = async (
    studyInstanceUID,
    seriesInstanceUID,
    sopInstanceUID,
    token
  ) => {
    try {
      const url = `https://healthcare.googleapis.com/v1/projects/nexusmd-prod/locations/us/datasets/dicom.nexus-md.factstore/dicomStores/production_phi/dicomWeb/studies/${studyInstanceUID}/series/${seriesInstanceUID}/instances/${sopInstanceUID}/rendered`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "image/jpeg",
        },
        responseType: "blob",
      });

      if (response.status === 200) {
        return URL.createObjectURL(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch thumbnail URL:", error);
    }
    return null;
  };

  const handleRowClick = async (study) => {
    setSelectedStudy(study);
    setLoadingThumbnails(true);

    try {
      const response = await api.get(
        `${GetPatientStudyMeta}/${study.StudyInstanceUID}`
      );
      const studyMeta = response.data[0];

      const tokenResponse = await api.get(DICOMProxyToken);
      const token = tokenResponse.data.token;

      const newThumbnails = [];
      for (let series of studyMeta.Series) {
        const instance = series.Instances[0];
        if (instance) {
          const thumbnailUrl = await getThumbnailUrl(
            studyMeta.StudyInstanceUID,
            series.SeriesInstanceUID,
            instance.SOPInstanceUID,
            token
          );
          if (thumbnailUrl) {
            newThumbnails.push(thumbnailUrl);
          }
        }
      }
      setThumbnails(newThumbnails);
    } catch (error) {
      console.error("Failed to fetch thumbnails", error);
    } finally {
      setLoadingThumbnails(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownloadStudyClick = async (study) => {
    try {
      setLoadingDownloadModal(true);
      resetCancelDownload();

      setDownloadMessage("Downloading...");
      setDownloadProgress(0);

      const tokenResponse = await api.get(DICOMProxyToken);
      const dicomToken = tokenResponse.data.token;
      const studyUrl = `https://healthcare.googleapis.com/v1beta1/projects/nexusmd-prod/locations/us/datasets/dicom.nexus-md.factstore/dicomStores/production_phi/dicomWeb/studies/${study.StudyInstanceUID}`;

      const interval = setInterval(() => {
        setDownloadProgress((prevProgress) => {
          const newProgress = prevProgress + 2;
          if (newProgress >= 90) {
            clearInterval(interval);
            return 90;
          }
          return newProgress;
        });
      }, 1000);

      await downloadZippedStudy(
        studyUrl,
        { Authorization: `Bearer ${dicomToken}` },
        `${study.StudyDate}_${study.Modality}_${study.StudyDescription}_${patient.first_name}${patient.last_name}.zip`,
        () => {
          clearInterval(interval);
          setDownloadProgress(100);
          setDownloadMessage("Download completed!");
          setNotification({
            open: true,
            message: "Download successful!",
            severity: "success",
          });
        }
      );
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Download cancelled");
      } else {
        console.error("Error downloading study:", error);
        setDownloadMessage("Failed to download study.");
        setNotification({
          open: true,
          message: "Download failed!",
          severity: "error",
        });
      }
    } finally {
      setLoadingDownloadModal(false);
    }
  };

  const stopDownload = () => {
    cancelDownload();
    setLoadingDownloadModal(false);
  };

  const columns = [
    { id: "StudyDescription", label: "Study Description" },
    { id: "Modality", label: "Modality" },
    { id: "StudyDate", label: "Study Date" },
  ];

  return (
    <div style={{ marginTop: "16px" }}>
      <InfoTable>
        <Typography style={{ marginBottom: "10px" }}>
          All Imaging ({totalRows !== null ? totalRows : "Loading..."})
        </Typography>
        <StyledTableContainer component={StyledPaper}>
          {studies === null ? (
            <div className="loadingContainer">
              <CircularProgress style={{ color: "#FBFEFF" }} />
            </div>
          ) : (
            <TableWrapper>
              <Table stickyHeader>
                <TableHead>
                  <TableRow className={classes.tableRow}>
                    {columns.map((column) => (
                      <TableCell key={column.id}>{column.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {studies.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className={classes.headerCell}
                        style={{ textAlign: "center" }}
                      >
                        No Studies Available
                      </TableCell>
                    </TableRow>
                  ) : (
                    studies.map((study) => (
                      <StyledTableRow
                        key={study.StudyInstanceUID}
                        onClick={() => handleRowClick(study)}
                        className={
                          selectedStudy?.StudyInstanceUID ===
                          study.StudyInstanceUID
                            ? "selected-row"
                            : ""
                        }
                        hover
                      >
                        <TableCell style={{ padding: "10px" }}>
                          {study.StudyDescription || "N/A"}
                        </TableCell>
                        <TableCell style={{ padding: "10px" }}>
                          {study.Modality}
                        </TableCell>
                        <TableCell style={{ padding: "10px" }}>
                          {study.StudyDate}
                        </TableCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
              {/* {expandedRows.includes(study.StudyInstanceUID) && (
                    <ExpandedTableRow>
                    <TableCell
                        colSpan={columns.length + 2}
                        style={{ padding: "16px" }}
                    >
                        <div
                        style={{
                            paddingBottom: 16,
                            display: "flex",
                            gap: "10px",
                        }}
                        >
                        <BasicButton
                            onClick={() =>
                            handleThumbnailClick(study.StudyInstanceUID)
                            }
                        >
                            Basic Viewer
                        </BasicButton>
                        <BasicButton
                            onClick={() =>
                            handleDownloadStudyClick(study)
                            }
                        >
                            Download Study
                        </BasicButton>
                        </div>
                    </TableCell>
                    </ExpandedTableRow>
                )} */}
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={totalRows || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                slotProps={{
                  select: {
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          backgroundColor: "#141b25",
                          color: "#FBFEFF",
                        },
                      },
                    },
                  },
                }}
              />
            </TableWrapper>
          )}
        </StyledTableContainer>
        <DownloadProgressModal
          open={loadingDownloadModal}
          progress={downloadProgress}
          message={downloadMessage}
          onCancel={stopDownload}
        />
      </InfoTable>
      {loadingThumbnails ? (
        <CircularProgress style={{ color: "#FBFEFF", marginTop: "16px" }} />
      ) : (
        selectedStudy && (
          <div
            style={{
              border: "4px solid #a5e0ff",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              marginTop: "10px",
            }}
          >
            <ThumbnailHeader>
              <Typography style={{ marginBottom: "10px" }}>
                Thumbnails ({thumbnails ? thumbnails.length : "Loading..."})
              </Typography>
              <Typography>
                <strong>
                  {selectedStudy.StudyDate} - {selectedStudy.StudyDescription}
                </strong>
              </Typography>
            </ThumbnailHeader>
            <div
              style={{
                display: "flex",
                gap: "10px",
                marginLeft: "16px",
                marginTop: "16px",
              }}
            >
              <BasicButton onClick={() => handleThumbnailClick(selectedStudy)}>
                Basic Viewer
              </BasicButton>
              <BasicButton
                onClick={() => handleDownloadStudyClick(selectedStudy)}
              >
                Download Study
              </BasicButton>
            </div>
            <OuterContainer>
              <ThumbnailList>
                {thumbnails &&
                  thumbnails.map((thumbnailUrl, index) => (
                    <ThumbnailListItem key={index}>
                      <img
                        key={index}
                        src={thumbnailUrl}
                        alt={`Thumbnail ${index + 1}`}
                        width={170}
                        height={170}
                        onClick={() => handleThumbnailClick(selectedStudy)}
                      />
                    </ThumbnailListItem>
                  ))}
              </ThumbnailList>
            </OuterContainer>
          </div>
        )
      )}
    </div>
  );
};

export default PatientStudies;
