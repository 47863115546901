import React from 'react';
import SearchIcon from "@mui/icons-material/Search";
import styled from "styled-components";
import InputBase from "@mui/material/InputBase";

const Search = styled.div`
  position: relative;
  border-radius: 4px;
  border: 1px solid white;
  background-color: transparent;
  margin-right: 16px;
  margin-bottom: 15px;
  margin-left: 0;
  width: 50%; 
  padding: 2.5px;
  display: flex;
  align-items: center;
`;

const SearchIconWrapper = styled.div`
  padding: 0 16px;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const StyledInputBase = styled(InputBase)`
  color: inherit;
  width: 100%;
  & .MuiInputBase-input {
    padding: 8px 8px 8px 40px;
    width: 100%;
  }
`;

const EhrSearch = ({ searchTerm, setSearchTerm }) => {

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search…"
        inputProps={{ "aria-label": "search" }}
        value={searchTerm}
        onChange={handleSearchChange}
      />
    </Search>
  );
};

export default EhrSearch;
